<template>
    <div v-loading="meta.loading"
         element-loading-text = "加载中,请稍后..."
         element-loading-background="rgba(0, 0, 0, 0.7)">
        <mt>
            <el-form :inline="true">
                <el-form-item>
                    <el-button class="funBtn" @click="onCreate">新建客户</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="funBtn" @click="onImport" style="margin-right: 40px">导入客户</el-button>
                </el-form-item>
                <el-form-item label="查询条件">
                    <el-input placeholder="请输入客户姓名或电话" v-model="param.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="param.sellerId" filterable remote placeholder="销售员姓名或电话" clearable
                               :remote-method="_querySeller"
                               :loading="meta.loading"
                    >
                        <el-option v-for="s in meta.sellers" :value="s.id" :label="s.name+'('+s.mobile+')'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onQuery">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onExport" v-if="isAdmin">导出</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onBatchEdit" v-if="isAdmin">批量编辑</el-button>
                </el-form-item>
            </el-form>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span class="title">客户列表</span>
            </div>
            <el-table stripe border height="700px"
                       :data="customers" ref="table" id="rebateSetTable" class="funBtn">
                <el-table-column label="" type="selection"></el-table-column>
                <el-table-column label="NO." type="index" width="80px"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="电话" prop="phone"></el-table-column>
                <el-table-column label="地址" prop="receiverAddress"></el-table-column>
                <el-table-column label="销售员工" prop="sellerName" sortable></el-table-column>
                <el-table-column label="已购订单数" prop="sellCount" sortable></el-table-column>
                <el-table-column label="消费金额" prop="sellMoney" sortable>
                    <template slot-scope="scope">
                        {{scope.row.sellMoney | fen2yuan }}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="操作" width="270">
                    <template scope="scope">
                        <el-button @click="detail(scope.row)">详情</el-button>
                        <el-button @click="edit(scope.row)" v-if="isAdmin">编辑</el-button>
                        <el-button @click="newOrder(scope.row)">下单</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination
                        @size-change="onQuery"
                        @current-change="onQuery"
                        :current-page.sync="param.page"
                        :page-sizes="[50, 100]"
                        :page-size.sync="param.size"
                        layout="sizes, prev, pager, next,total"
                        :total="param.total">
                </el-pagination>
            </div>
            <el-dialog :visible.sync="showDialog" title="新建/编辑客户" min-width="1000px">
                <el-form align="left" :model="newCustomer" :rules="rules" ref="newCustomer"
                         label-position="right" label-width="160px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="newCustomer.name" @change="onInputName"
                                          style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="newCustomer.phone" @change="onInputPhone"
                                          style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话2" prop="phone2">
                                <el-input v-model="newCustomer.phone2" style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="gender">
                                <el-radio-group v-model="newCustomer.gender">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="0">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="年龄" prop="age">
                                <el-input-number v-model="newCustomer.age" :precision="0" :controls="false"
                                                 style="width: 200px"></el-input-number>
                            </el-form-item>
                            <el-form-item label="卡号" prop="card">
                                <el-input v-model="newCustomer.card" style="width: 200px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="默认收货人" prop="receiver">
                                <el-input v-model="newCustomer.receiver" style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="默认收货人电话" prop="receiverPhone">
                                <el-input v-model="newCustomer.receiverPhone" style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="默认收货地址" prop="receiverAddress">
                                <el-input v-model="newCustomer.receiverAddress" style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item label="当前积分" prop="score">
                                <el-input-number v-model="newCustomer.score" :precision="0" :controls="false"
                                                 style="width: 200px"></el-input-number>
                            </el-form-item>
                            <el-form-item label="请选择销售员" prop="sellerId">
                                <el-select v-model="newCustomer.sellerId" filterable clearable remote placeholder="请选择"
                                           :remote-method="_querySeller"
                                           :loading="meta.loading" style="width: 200px">
                                    <el-option v-for="s in meta.sellers" :value="s.id"
                                               :label="s.name+'('+s.mobile+')'"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="备注" prop="receiver">
                                <el-input v-model="newCustomer.remark" style="width: 200px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onCreateOk">确定</el-button>
                    <el-button @click="onCreateCancel">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showBatchEdit" width="500px" title="编辑客户">
                <el-form :model="newSeller" v-model="newSeller">
                    <el-form-item label="销售员">
                        <el-select v-model="newSeller.id" filterable clearable remote placeholder="请输入销售员姓名或电话"
                                   :remote-method="_querySeller"
                                   :loading="meta.loading">
                            <el-option v-for="s in meta.sellers" :value="s.id"
                                       :label="s.name+'('+s.mobile+')'"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button type="primary" @click="onBatchEditSure">确定</el-button>
                    <el-button @click="showBatchEdit=false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showImport" width="450px">
                <el-upload
                        class="upload-demo"
                        ref="upload"
                        action="/api/customer/upload"
                        :file-list="imports.file"
                        :limit="1"
                        :before-upload="beforeUpload"
                        :on-success="onUploadSuccess"
                        :on-error="onUploadError"
                        :auto-upload="false">
                    <el-button slot="trigger" type="primary">选取文件</el-button>
                    <el-button style="margin-left: 10px;" type="success" @click="onImportSure">上传到服务器
                    </el-button>
                    <el-button style="margin-left: 10px;" @click="onDownloadTemplate">下载模板</el-button>
                    <div style="color:red" slot="tip" class="el-upload__tip">单次导入最多不能超过500条,手机号或电话号码重复的客户不能重复导入</div>
                </el-upload>
            </el-dialog>
        </el-card>
    </div>

</template>

<script>
    import FileSaver from 'file-saver'
    import XLSX from 'xlsx'
    import mt from "@/components/MainTop.vue"

    export default {
        name: "List",
        components: {mt},
        props: ["sellerId"],
        data() {
            return {
                param: {
                    name: "",
                    sellerId: "",
                    page: 1,
                    size: 50,
                    total: 0
                },
                showDialog: false,
                newCustomer: {
                    name: "",
                    phone: "",
                    phone2: "",
                    gender: 1,
                    age: 18,
                    card: "",
                    receiver: "",
                    receiverPhone: "",
                    receiverAddress: "",
                    score: 0,
                    sellerId: "",
                    remark: "",
                },
                rules: {
                    name: [{required: true, message: '请输入客户姓名', trigger: 'blur'}],
                    phone: [{required: true, message: '请输入客户联系电话', trigger: 'blur', pattern: /^\d{7,11}$/}],
                },
                meta: {
                    sellers: [],//全体销售员
                    loading: false
                },
                customers: [],

                showBatchEdit: false,
                newSeller: {
                    id: ""
                },
                showImport: false,
                imports: {
                    file: []
                }
            }
        },
        mounted() {
            this.param.sellerId = this.sellerId;
            this._querySeller();
            this.onQuery();
        },
        methods: {
            _querySeller(name) {
                this.$http.get("/api/seller/find", {params: {name: name, page: 0, size: 50, state: 1}})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                                this.meta.sellers = rsp.data.data || [];
                        }
                    }).catch(() => {
                        this.meta.sellers = [];
                })
            },
            onQuery() {
                let _this = this;
                this.meta.loading= true;
                this.$http.get("/api/customer/find", {params: this.param})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            _this.customers = rsp.data.data || [];
                            this.param.total = rsp.data.count;
                            this.meta.loading= false;
                        }
                    })
            },

            detail(row) {
                this.$router.push({name: 'CustomerDetail', params: {customerId: row.id}})
            },
            edit(row) {
                this.clearData();
                this._querySeller();
                this.newCustomer = JSON.parse(JSON.stringify(row));
                this.showDialog = true;
            },
            newOrder(row) {
                localStorage.removeItem("cartGoods");
                this.$router.push({name: "CustomerNewOrder", params: {form: row}});
            },
            onCreate() {
                this.showDialog = true;
            },
            onCreateCancel() {
                this.showDialog = false;
                this.clearData();
            },
            onCreateOk() {
                this.$refs['newCustomer'].validate((valid) => {
                        if (!valid) {
                            return
                        }
                        let param = JSON.parse(JSON.stringify(this.newCustomer))
                        if (this.newCustomer.id) {//编辑
                            let a = this.$http.patch("/api/customer/edit/" + param.id, param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("编辑成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                    }
                                }).catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);

                                })
                        } else {//新建
                            let a = this.$http.post("/api/customer/create", param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("创建成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                    }
                                })
                                .catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);
                                })
                        }
                    }
                );

            },
            clearData() {
                this.newCustomer = {
                    name: "",
                    phone: "",
                    phone2: "",
                    gender: 1,
                    age: 18,
                    card: "",
                    receiver: "",
                    receiverPhone: "",
                    receiverAddress: "",
                    score: 0,
                    sellerId: "",
                    remark: "",
                }
            },
            onBatchEdit() {
                let selectId = this.$refs['table'].selection.map(c => c.id);
                if (!selectId['length']) {
                    this.$message.error("请选择要编辑的用户")
                    return;
                }
                this.showBatchEdit = true;
            },
            onBatchEditSure() {
                let ids = this.$refs['table'].selection.map(a => a.id);
                let param = {
                    ids: ids,
                    sellerId: this.newSeller.id
                }
                this.$http.patch("/api/customer/editSeller", param)
                    .then((rsp) => {
                        if (rsp.data.status == 200) {
                            this.$message.success("编辑成功");
                            this.showBatchEdit = false;
                            this.clearData();
                            this.onQuery();
                        }
                    }).catch((rsp) => {
                    this.$message.error(rsp.response.data.message);
                })
            },
            onExport() {
                /* generate workbook object from table */
                let wb = XLSX.utils.table_to_book(document.querySelector('#rebateSetTable'));
                /* get binary string as output */
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                try {
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '客户信息.xlsx');
                } catch (e) {
                    if (typeof console !== 'undefined')
                        console.log(e, wbout)
                }
                return wbout
            },
            onImport() {
                this.showImport = true
            },
            onDownloadTemplate() {
                window.location.href = "/导入模板.csv"
            },
            onImportSure() {
                this.$refs.upload.submit();
            },
            onUploadError(err, file, fileList) {
                this.$alert(JSON.parse(err.message).message, '错误', {
                    confirmButtonText: '确定',
                    type: 'error'
                });
            },
            onUploadSuccess(response, file, fileList) {
                this.showImport = false;
                if(response.status==200){
                  this.$message.success("导入成功");
                  this.onQuery();
                }else{
                  this.$message.error(response['message'])
                }
            },
            onInputName(name) {
                this.newCustomer.receiver = name;
            },
            onInputPhone(phone) {
                this.newCustomer.receiverPhone = phone
            },
            beforeUpload(file) {
                if (!file.name.endsWith(".csv")) {
                    this.$message.error('请先下载模板,填写后上传模板文件!')
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style scoped>

</style>
